const routes = [
    {
        name: 'home_page',
        path: '/',
        component: () => import('../components/view/IndexPage/HomePage.vue'),
        meta: {
            title: 'Ninja-bot',
        },
    },
    {
        name: 'share_bot',
        path: '/share-bot/:share_link_uuid',
        component: () => import('../components/view/UseShareLink.vue'),
        props: true,
        meta: {
            title: 'Пригласительная ссылка',
            sitemap: {
                ignoreRoute: true
            },
        },
    },
    {
        path: '/docs',
        component: () => import('../components/view/Documentation/HowUse.vue'),
        meta: {
            title: 'Документация',
        },
        children: [
            {
                name: 'doc_get_started',
                path: 'get-started',
                meta: {
                    title: 'Как начать',
                },
                component: () => import('../components/view/Documentation/DocsGetStarted.vue'),
            },
            {
                name: 'doc_delay_publish',
                path: 'delay-publish',
                meta: {
                    title: 'Постинг',
                },
                component: () => import('../components/view/Documentation/DocsDelayPublish.vue'),
            },
            {
                name: 'doc_forwarder',
                path: 'forwarder',
                meta: {
                    title: 'Документация перенаправление постов',
                },
                component: () => import('../components/view/Documentation/DocsForwardPost.vue'),
            },
            {
                name: 'doc_dictionary',
                path: 'dictionary',
                meta: {
                    title: 'Документация справочники',
                },
                component: () => import('../components/view/Documentation/DocsDictionary.vue'),
            },
            {
                name: 'doc_how_to_add_bot',
                path: 'add-bot',
                meta: {
                    title: 'Как добавить бота',
                },
                component: () => import('../components/view/Documentation/DocsHowAddBot.vue'),
            },
            {
                name: 'doc_tags',
                path: 'tags',
                meta: {
                    title: 'Документация теги',
                },
                component: () => import('../components/view/Documentation/DocsTags.vue'),
            },
            {
                name: 'doc_feedback_bot',
                path: 'feedback-bot',
                meta: {
                    title: 'Бот обратной связи',
                },
                component: () => import('../components/view/Documentation/DocsFeedbackBot.vue'),
            },
            {
                name: 'doc_builder',
                path: 'builder',
                meta: {
                    title: 'Документация конструктор',
                },
                component: () => import('../components/view/Documentation/DocsBuilder.vue'),
                children: [
                    {
                        name: 'doc_builder_message',
                        path: 'message',
                        meta: {
                            title: 'Блок "Сообщение"',
                        },
                        component: () => import('../components/view/Documentation/DocsBuilderMessage.vue'),
                    },
                    {
                        name: 'doc_builder_trigger',
                        path: 'trigger',
                        meta: {
                            title: 'Блок "Условие"',
                        },
                        component: () => import('../components/view/Documentation/DocsBuilderTrigger.vue'),
                    },
                    {
                        name: 'doc_builder_command',
                        path: 'command',
                        meta: {
                            title: 'Блок "Действие"',
                        },
                        component: () => import('../components/view/Documentation/DocsBuilderCommand.vue'),
                    },
                    {
                        name: 'doc_builder_delay',
                        path: 'delay',
                        meta: {
                            title: 'Блок "Задержка"',
                        },
                        component: () => import('../components/view/Documentation/DocsBuilderDelay.vue'),
                    },
                    {
                        name: 'doc_builder_question',
                        path: 'question',
                        meta: {
                            title: 'Блок "Вопрос"',
                        },
                        component: () => import('../components/view/Documentation/DocsBuilderQuestion.vue'),
                    },
                ]
            },
            {
                name: 'doc_groups',
                path: 'groups',
                meta: {
                    title: 'Функции для Telegram групп',
                },
                component: () => import('../components/view/Documentation/DocAboutGroup/DocsGroups.vue'),
            },
            {
                name: 'doc_context',
                path: 'context',
                meta: {
                    title: 'Документация переменные',
                },
                component: () => import('../components/view/Documentation/DocsContext.vue'),
            },
        ],
    },
    {
        path: "/",
        component: () => import('../components/view/Layout/Layout.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true
            },
        },
        children: [
            {
                name: 'bots_list',
                path: 'bots',
                component: () => import('../components/view/Bots/BotList.vue'),
                meta: {
                    title: 'Список ботов',
                },
            },
            {
                path: 'changelog',
                name: 'changelog',
                component: () => import('../components/view/ChangeLog/ChangeLog.vue'),
            },
            {
                path: 'profile',
                name: 'profile',
                component: () => import('../components/view/Profile/Profile.vue'),
            },
            {
                name: 'bot',
                path: 'bot/:scenario_uuid',
                component: () => import('../components/view/Bot/BotLayout.vue'),
                props: true,
                children: [
                    {
                        name: 'subscribers',
                        path: 'subscribers',
                        component: () => import('../components/view/Subscriber/SubscribersList.vue'),
                        props: true,
                        meta: {
                            title: 'База пользователей',
                        },
                    },
                    {
                        name: 'mailing',
                        path: 'mailing',
                        component: () => import('../components/view/Mailing/MailingList.vue'),
                        props: true,
                        meta: {
                            title: 'Рассылки',
                        },
                    },
                    {
                        name: 'integrations',
                        path: 'integrations',
                        component: () => import('../components/view/Integration/IntegrationGetForList.vue'),
                        props: true,
                        meta: {
                            title: 'Интеграции',
                        },
                    },
                    {
                        name: 'create_integration',
                        path: 'integration/create/:integration_type',
                        component: () => import('../components/view/Integration/CreateIntegration.vue'),
                        props: true,
                        meta: {
                            title: 'Создание интеграции',
                        },
                    },
                    {
                        name: 'update_integration',
                        path: 'integration/update/:integration_uuid',
                        component: () => import('../components/view/Integration/UpdateIntegration.vue'),
                        props: true,
                        meta: {
                            title: 'Обновление интеграции',
                        },
                    },
                    {
                        name: 'mailing_create',
                        path: 'mailing/create',
                        component: () => import('../components/view/Mailing/MailingCreate.vue'),
                        props: true,
                        meta: {
                            title: 'Создание рассылки',
                        },
                    },
                    {
                        name: 'mailing_edit',
                        path: 'mailing/edit/:mailing_uuid',
                        component: () => import('../components/view/Mailing/MailingEdit.vue'),
                        props: true,
                        meta: {
                            title: 'Редактирование рассылки',
                        },
                    },
                    {
                        name: 'feedback',
                        path: 'feedback',
                        component: () => import('../components/view/Feedback/FeedbackBot.vue'),
                        props: true,
                        meta: {
                            title: 'Бот обратной связи',
                        },
                    },
                    {
                        name: 'dialogs',
                        path: 'dialogs',
                        component: () => import('../components/view/Dialog/DialogList.vue'),
                        props: true,
                        meta: {
                            title: 'Диалоги',
                        },
                    },
                    {
                        name: 'about_bot',
                        path: 'settings',
                        component: () => import('../components/view/Bots/AboutBot.vue'),
                        props: true,
                        meta: {
                            title: 'Настройка бота',
                        },
                    },
                    {
                        name: 'bot_builder',
                        path: 'builder',
                        component: () => import('../components/view/Bots/BotBuilder.vue'),
                        props: true,
                        meta: {
                            title: 'Сценарий бота',
                        },
                    },
                    {
                        name: 'bot_groups',
                        path: 'groups',
                        component: () => import('../components/view/GroupChat/GroupChatList.vue'),
                        props: true,
                        meta: {
                            title: 'Группы',
                        },
                    },
                    {
                        name: 'group_card',
                        path: 'group/:dialog_uuid',
                        component: () => import('../components/view/GroupChat/GroupChatCard.vue'),
                        props: true,
                        meta: {
                            title: 'Группа',
                        },
                    },
                    {
                        name: 'group_statistics',
                        path: 'statistics/:dialog_uuid',
                        component: () => import('../components/view/GroupChat/GroupStatistics.vue'),
                        props: true,
                        meta: {
                            title: 'Статистика',
                        },
                        children: [
                            {
                                name: 'group_statistics_subscription',
                                path: 'subscription',
                                component: () => import('../components/view/GroupChat/GroupStatsSubscription.vue'),
                                props: true,
                                meta: {
                                    title: 'Статистика подписок',
                                },
                            },
                            {
                                name: 'group_statistics_messages',
                                path: 'messages',
                                component: () => import('../components/view/GroupChat/GroupStatsMessages.vue'),
                                props: true,
                                meta: {
                                    title: 'Статистика сообщений',
                                },
                            },
                            {
                                name: 'group_statistics_report',
                                path: 'report',
                                component: () => import('../components/view/GroupChat/GroupStatsReport.vue'),
                                props: true,
                                meta: {
                                    title: 'Отчеты',
                                },
                            }
                        ]
                    },
                    {
                        name: 'auto_approve_join_request',
                        path: 'auto-approve/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/AutoApproveJoinRequest.vue'),
                        props: true,
                        meta: {
                            title: 'Авто прием заявок на вступление',
                        },
                    },
                    {
                        name: 'group_work_hours',
                        path: 'work_hours/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/WorkingHours.vue'),
                        props: true,
                        meta: {
                            title: 'Часы работы',
                        },
                    },
                    {
                        name: 'invite_link',
                        path: 'invite-link/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/InviteLink.vue'),
                        props: true,
                        meta: {
                            title: 'Заявки на вступление',
                        },
                    },
                    {
                        name: 'group_filter_messages',
                        path: 'filter/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/FilterMessages.vue'),
                        props: true,
                        meta: {
                            title: 'Фильтр сообщений',
                        },
                    },
                    {
                        name: 'group_periodic_messages',
                        path: 'periodic-messages/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/PeriodicMsg/PeriodicMessages.vue'),
                        props: true,
                        meta: {
                            title: 'Периодические сообщения',
                        },
                    },
                    {
                        name: 'service_group',
                        path: 'service-group/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/ServiceGroup.vue'),
                        props: true,
                        meta: {
                            title: 'Сервисная группа',
                        },
                    },
                    {
                        name: 'group_working_hours',
                        path: 'working-hours/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/WorkingHours.vue'),
                        props: true,
                        meta: {
                            title: 'Часы работы',
                        },
                    },
                    {
                        name: 'face_control',
                        path: 'face_control/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/FaceControl/FaceControlList.vue'),
                        props: true,
                        meta: {
                            title: 'Face control',
                        },
                    },
                    {
                        name: 'defender',
                        path: 'defender/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/DefenderControl.vue'),
                        props: true,
                        meta: {
                            title: 'Зашита от накрутки',
                        },
                    },
                    // todo: deprecated
                    {
                        name: 'bot_users',
                        path: 'users',
                        component: () => import('../components/view/Bots/BotUsers.vue'),
                        props: true,
                    },
                    {
                        name: 'context_list',
                        path: 'contexts',
                        component: () => import('../components/view/Context/ContextList.vue'),
                        props: true,
                        meta: {
                            title: 'Переменные',
                        },
                    },
                    {
                        name: 'calendar',
                        path: 'calendar',
                        component: () => import('../components/view/Calendar/EventCalendar.vue'),
                        props: true,
                        meta: {
                            title: 'Календарь',
                        },
                    },
                    {
                        name: 'tags_list',
                        path: 'tags',
                        component: () => import('../components/view/Tags/TagsList.vue'),
                        props: true,
                        meta: {
                            title: 'Теги',
                        },
                    },
                    {
                        name: 'posting',
                        path: 'posting/:forwarder_uuid',
                        component: () => import('../components/view/Forward/ForwarderCard.vue'),
                        props: true,
                        meta: {
                            title: 'Перенаправление постов',
                        },
                    },
                    {
                        name: 'content_plan',
                        path: 'posts',
                        component: () => import('../components/view/ContentPlan/ContentPlanCalendar.vue'),
                        props: true,
                        children: [
                            {
                                name: 'posts',
                                path: 'list',
                                props: true,
                                component: () => import('../components/view/ContentPlan/PostsList.vue'),
                                meta: {
                                    title: 'Постинг',
                                },
                            },
                            {
                                name: 'posts_calendar',
                                path: 'calendar',
                                props: true,
                                component: () => import('../components/view/ContentPlan/PostsCalendar.vue'),
                                meta: {
                                    title: 'Календарь рассылок',
                                },
                            },
                            {
                                name: 'forwarder_list',
                                path: 'posting',
                                component: () => import('../components/view/Forward/ForwarderList.vue'),
                                props: true,
                                meta: {
                                    title: 'Репостинг',
                                },
                            },
                            {
                                name: 'queue_list',
                                path: 'queue',
                                component: () => import('../components/view/Queue/QueueList.vue'),
                                props: true,
                                meta: {
                                    title: 'Очереди публикаций',
                                },
                            },
                        ],
                    },
                    {
                        name: 'post_card',
                        path: 'post/:post_uuid',
                        props: true,
                        component: () => import('../components/view/ContentPlan/PostCard.vue'),
                        meta: {
                            title: 'Редактирование поста',
                        },
                    },
                    {
                        name: 'triggers',
                        path: 'triggers/:dialog_uuid',
                        component: () => import('../components/view/Triggers/TriggersList.vue'),
                        props: true,
                    },
                    {
                        name: 'welcome_card',
                        path: 'welcome/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/WelcomeGoodbyeTrigger/WelcomeCard.vue'),
                        props: true,
                        meta: {
                            title: 'Приветствие в группах',
                        },
                    },
                    {
                        name: 'bot_commands',
                        path: 'commands',
                        component: () => import('../components/view/BotCommand/BotCommandList.vue'),
                        props: true,
                        meta: {
                            title: 'Команды бота',
                        },
                    },
                    {
                        name: 'goodbye_card',
                        path: 'goodbye/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/WelcomeGoodbyeTrigger/GoodbyeCard.vue'),
                        props: true,
                        meta: {
                            title: 'Прощание в группах',
                        },
                    },
                    {
                        name: 'commands_settings',
                        path: 'warning/:dialog_uuid',
                        component: () => import('../components/view/GroupComponents/BotCommands/CommandsSettings.vue'),
                        props: true,
                        meta: {
                            title: 'Команды бота',
                        },
                    },
                    {
                        name: 'trigger_for_card',
                        path: 'trigger/:uuid',
                        component: () => import('../components/view/Triggers/TriggerForCard.vue'),
                        props: true,
                    },
                    {
                        name: 'dictionary_for_list',
                        path: 'dictionaries',
                        component: () => import('../components/view/Dictionaries/DictionaryForList.vue'),
                        props: true,
                    },
                    {
                        name: 'dictionary_for_card',
                        path: 'dictionaries/:uuid',
                        component: () => import('../components/view/Dictionaries/DictionaryForCard.vue'),
                        props: true,
                    },
                ]
            }
        ]
    },
    {
        name: 'auth',
        path: "/auth",
        component: () => import('../components/view/Auth/SignIn.vue'),
        meta: {
            title: 'Авторизация',
            sitemap: {
                ignoreRoute: true
            },
        },
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import('../components/view/NotFound.vue'),
        meta: {
            title: 'Страница не найдена',
        }
    }
];

export { routes }